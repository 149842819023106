import React, { useState } from 'react';
import voxxsys_logo from '../assest/voxxsys_logo.png';
import { RxHamburgerMenu } from "react-icons/rx";
import { IoIosCloseCircle } from "react-icons/io";
import { IoMdClose } from "react-icons/io";

const Navbar = () => {
    // const modal = useRef(null);
    const [isPopupOpen, setPopupOpen] = useState(false);
    
  return (
    <div className='navbar-container'>
        <div className='navbar-logo-div'>
            <div className='logo'>
                <img src={voxxsys_logo} alt="logo" className='logo-image' />
            </div>
        </div>
        <div className={`nav-hamburger `}>
            <RxHamburgerMenu onClick={() => setPopupOpen(true)} className='icon-hamburger'/>
            <div className={`${isPopupOpen ? 'popup-open' : 'popup-closed'}`}>
                <div className='navbar-option pop-closed-button' onClick={() => setPopupOpen(false)}><IoMdClose className='icon-closed'/></div>
                {/* <div className='navbar-option pop' to="/services" ><a href='#questionId'>About Us</a></div>
                <div className='navbar-option pop'>Products / Services</div>
                <div className='navbar-option pop'>Testimonials</div>
                <div className='navbar-option pop'>Careers</div>
                <div className='navbar-option pop'>News / Events</div> */}
                <div className='navbar-option' onClick={() => setPopupOpen(false)}><a href='#homeId' className='link pop'>Home</a></div>
                <div className='navbar-option' onClick={() => setPopupOpen(false)}><a href='#aboutId' className='link pop'>About Us</a></div>
                <div className='navbar-option' onClick={() => setPopupOpen(false)}><a href='#productId' className='link pop'>Products / Services</a></div>
                <div className='navbar-option' onClick={() => setPopupOpen(false)}><a href='#questionId' className='link pop'>Questions</a></div>
                <div className='navbar-option' onClick={() => setPopupOpen(false)}><a href='' className='link pop'>News Feed</a></div>
                <div className='navbar-option' onClick={() => setPopupOpen(false)}><a href='' className='link pop'>Careers</a></div>
                <div className='navbar-option' onClick={() => setPopupOpen(false)}><a href='#contactId' className='link pop'>Contact Us</a></div>
                <div className='nav-pop-button'>
                    <div className='button-1 pop-button'>Client Portal</div>
                </div>
            </div>
        </div>
        <div className='navbar-options'>
            {/* <div className='navbar-option'><a href='#aboutId'>About Us</a></div>
            <div className='navbar-option'><a href='#productId'>Products / Services</a></div>
            <div className='navbar-option'><a href='#questionId'>Testimonials</a></div>
            <div className='navbar-option'><a href='#questionId'>Careers</a></div>
            <div className='navbar-option'><a href='#questionId'>News / Events</a></div> */}
            <div className='navbar-option'><a href='#homeId' className='link'>Home</a></div>
            <div className='navbar-option'><a href='#aboutId' className='link'>About Us</a></div>
            <div className='navbar-option'><a href='#productId' className='link'>Products / Services</a></div>
            <div className='navbar-option'><a href='#questionId' className='link'>Questions</a></div>
            <div className='navbar-option'><a href='' className='link'>News Feed</a></div>
            <div className='navbar-option'><a href='' className='link'>Careers</a></div>
            <div className='navbar-option'><a href='#contactId' className='link'>Contact Us</a></div>
            {/* <div className='navbar-option'><a href='#questionId'>News / Events</a></div> */}
            <div className='button1-div'>
                <div className='button-1'>Client Portal</div>
            </div>
        </div>
    </div>
  )
}

export default Navbar;