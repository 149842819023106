import React, { useState } from 'react';
import { FaCircleQuestion } from "react-icons/fa6";

const Question = () => {
    const [showIndex, setShowIndex] = useState();
    const questionsData = [
        {
            title: "What is VoxxSys International (PVT) Ltd?",
            description: "VoxxSys International is a digital solutions company specializing in custom software development, POS systems, web design, and digital content creation. We provide cutting-edge technology solutions tailored to meet the unique needs of businesses in various industries"
        },
        {
            title: "What services does VoxxSys International offer?",
            description: "We offer a wide range of services including software development, point-of-sale (POS) system design, website development, digital content creation, and SEO optimization."
        },
        {
            title: "What industries do we serve?",
            description: "VoxxSys serves clients across various industries, including retail, hospitality, finance, healthcare, and more."
        },
        {
            title: "Why should I choose VoxxSys International for my project?",
            description: "At VoxxSys, we prioritize understanding our clients’ unique needs and delivering innovative, reliable solutions. Our team is highly skilled and experienced, and we take pride in our commitment to quality, security, and customer satisfaction."
        },
        {
            title: "What types of POS systems does VoxxSys develop?",
            description: "VoxxSys develops POS systems designed for both small and large businesses across industries. We have both one-size-fits-all systems as well as custom made POS systems. Our POS solutions are customizable, user-friendly, and include features like inventory management, customer data tracking, and secure payment processing."
        },
        {
            title: "Does VoxxSys provide ongoing support for its software solutions?",
            description: "Yes, we provide comprehensive support and maintenance for all of our projects, including websites, software, and POS solutions. Our support team is here to ensure your systems run smoothly and are updated to meet evolving business needs from Monday to Saturday from 9 am to 7 pm"
        },
        {
            title: "How long does it take to complete a project with VoxxSys?",
            description: "Project timelines vary depending on the complexity and scope of the project. We work closely with clients to establish realistic timelines and keep them informed throughout the development process to ensure timely delivery."
        },
        {
            title: "Does VoxxSys offer product training and support after project completion?",
            description: "Yes, we provides comprehensive product training and support after your project is complete. Our team ensures you and your staff are fully trained to use the software or POS systems effectively. Additionally, we offer ongoing support and maintenance services to keep your systems running smoothly and up to date with any required upgrades"
        },
        {
            title: "Can VoxxSys help improve my website’s SEO and online presence?",
            description: "Absolutely. We provide SEO optimization as part of our digital content and website services. Our team can help boost your online presence by implementing SEO best practices and creating high-quality, optimized content that attracts the right audience."
        },
        {
            title: "How does VoxxSys ensure data security in its solutions?",
            description: "Data security is a top priority at VoxxSys. We use advanced security protocols and encryption techniques to safeguard your data. Additionally, we follow industry best practices to protect your business from cybersecurity threats."
        },
        {
            title: "What is the process to start a project with VoxxSys International?",
            description: "Starting a project with VoxxSys is easy! Simply contact us through our website at https://voxxsys.com or by phone at +9477 717 0107. We’ll schedule an initial consultation to discuss your needs and provide recommendations. From there, we’ll work together to create a customized plan tailored to your objectives."
        },
        {
            title: "Where is VoxxSys International located, and do you work with clients remotely?",
            description: "We have our roots mainly in three countries; Sri Lanka, Dubai, and New Zealand. While these are our main serving locations, we serve clients globally and are fully equipped to work with clients remotely. Our team uses advanced communication tools to ensure seamless collaboration, no matter where you are"
        }
    ];

    const handleShowQA = (index) => {
        setShowIndex(index);
    }

  return (
    <div className='main-container' id="questionId">
        <div className='title'>Frequenty Asked Questions</div>
        <div className='main-para'>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed</div>
        <div className='question-sub-container'>
            {questionsData.map((data, index) => (
                <div className='question'>
                    <div className='question-icon'><FaCircleQuestion className='' size={30}/></div>
                    <div>
                        <div className='title4 qt' onClick={() => handleShowQA(index)}>{data.title}</div>
                        <div className={`${showIndex === index ? 'sub-text' : 'hide-text'}`}>{data.description}</div>
                    </div>
                </div>
            ))}
        </div>
    </div>
  )
}

export default Question;