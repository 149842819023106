import React from 'react';
import TextField from '../components/TextField';

const Home = () => {
    const data = {
        title: "WELCOME",
        subTitle: "to our company",
        paragraph: "With over a decade of experience in the field, VoxxSys has become one of the major players in technology-related products and keeps growing every day, with greater future goals to bring out the top-performing digital products for your brands and businesses.",
        buttonTitle: "Login"
    };
    
    return (
        <div className='home-container' id="homeid">
            <TextField data={data} head="home"/>
            <div className='home-image-div'>
                <iframe 
                    className='home-image'
                    src="https://www.youtube.com/embed/axC4hJre0oc?rel=0&autoplay=0" 
                    title="YouTube video player" 
                    frameBorder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                    allowFullScreen>
                </iframe>
            </div>
        </div>
    );
}

export default Home;



// import React from 'react';
// import TextField from '../components/TextField';

// const Home = () => {
//     const data = {
//         title: "WELCOME",
//         subTitle: "to our company",
//         paragraph: "With over a decade of experience in the field, VoxxSys has become one of the major players in technology-related products and keeps growing every day, with greater future goals to bring out the top-performing digital products for your brands and businesses.",
//         buttonTitle: "Login"
//     };
    
//     return (
//         <div className='home-container' id="homeid">
//             <TextField data={data} head="home"/>
//             <div className='home-image-div'>
//                 {/* Embed the YouTube video with an iframe instead of video */}
//                 <iframe 
//                     className='home-image'
//                     src="https://www.youtube.com/embed/axC4hJre0oc" 
//                     title="YouTube video player" 
//                     frameBorder="0" 
//                     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
//                     allowFullScreen>
//                 </iframe>
//             </div>
//         </div>
//     );
// }

// export default Home;



// import React from 'react';
// import TextField from '../components/TextField';
// import img1 from '../assest/image.jpg';

// const Home = () => {
//     const data = {
//         title: "WELCOME",
//         subTitle: "to our company",
//         paragraph: "With over a decade of experience in the field, VoxxSys has become one of the major players in technology-related products and keeps growing every day, with greater future goals to bring out the top-performing digital products for your brands and businesses.",
//         buttonTitle: "Login"
//     }
    
//     return (
//         <div className='home-container' id="homeid">
//             <TextField data={data} head="home"/>
//             <div className='home-image-div'>
//                 {/* <img src={img1} alt='img' className='home-image'/> */}
//                 <video width="320" height="240" controls>
//                     <source src="https://www.youtube.com/watch?v=axC4hJre0oc" type="video/mp4"/>
//                 </video> 
//             </div>
//         </div>
//     )
// }

// export default Home;