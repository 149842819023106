import React from 'react';

const AboutCard = ({ data }) => {
  return (
    <div className='aboutcard'>
      <div className='aboutcard-count'>{data.count}{data.title !== "Countries" && '+'} </div>
      <div className='aboutcard-title'>{data.title}</div>
    </div>
  )
}

export default AboutCard;