import './App.css';
import Bubble from './components/bubble/Bubble';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import SocialIcons from './components/SocialIcons';
import About from './pages/About';
import Company from './pages/Company';
import Connect from './pages/Connect';
import Feedback from './pages/Feedback';
import Home from './pages/Home';
import ProductServices from './pages/ProductServices';
import Question from './pages/Question';
import Team from './pages/Team';
import Tech from './pages/Tech';

function App() {
  return (
    <div className="App">
      <div className='app-container'>
          <Navbar />
          {/* <hr className='nav-hr'/> */}
          <Home />
          {/* <hr className='common-hr'/> */}
          <About />
          {/* <hr className='common-hr'/> */}
          {/* <hr className='common-hr'/> */}
          <ProductServices />
          {/* <hr className='common-hr'/> */}
          <Question />
          <Team />
          <Tech />
          <Company />
          <Feedback />
          {/* <hr className='common-hr'/> */}
          <Connect />
          <SocialIcons />
        </div>
          <Footer />
    </div>
  );
}

export default App;
