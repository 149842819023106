import React, { useEffect} from 'react';
import './Slider.css';

const Slider = ({ children, setSlideIndex, slideIndex }) => {
  const slideCount = React.Children.count(children);

  useEffect(() => {
    const interval = setInterval(() => {
      setSlideIndex((prevIndex) => (prevIndex + 1) % slideCount);
    }, 5000);
    return () => clearInterval(interval);
  }, [slideCount]);


  return (
    <div className="slider-container">
      {React.Children.map(children, (child, index) => (
        <div
          className="slide"
          style={{ display: index === slideIndex ? 'block' : 'none' }}
        >
          {child}
        </div>
      ))}
    </div>
  );
};

export default Slider;
