// import React, { useState } from 'react';
// import TextField from '../components/TextField';
// import AboutCard from '../components/AboutCard';
// import Slider from '../components/slider/Slider';

// const About = () => {
//     const [slideIndex, setSlideIndex] = useState(0);
//     const data = {
//         title: "About",
//         titleY: "Us",
//         paragraph: `VoxxSys operates with a straightforward vision: to create simple yet sophisticated digital solutions that represent your brand and enhance your business's efficiency. Leveraging state-of-the-art technologies, VoxxSys ensures high productivity and efficiency in all our digital products.
//             We provide a wide range of digital solutions, from static websites to e-commerce platforms that expand your reach globally. VoxxSys also develops ERP, CRM, and HRM systems to support professional, sustainable, and efficient work environments.\n
//             With a strong foundation since the early 2000s and an experienced board of directors, we are proud to be at the forefront, supported by a skilled team dedicated to delivering high-quality products. VoxxSys presents itself as a trustworthy global label our clients can rely on.
//             Join us on a journey of growth, where your brand’s digital potential is not just realized but elevated.`,
//         buttonTitle: "Read More"
//     }
      
//     const cardData = [
//         {
//             title: "Countries",
//             count: 3,
//         },
//         {
//             title: "Customers",
//             count: 300,
//         },
//         {
//             title: "Years of Experince",
//             count: 3,
//         },
//         {
//             title: "Completed Projects",
//             count: 3,
//         },
//     ]
        
//     const handleNextImg = () => {
//         if(cardData.length > slideIndex) {
//           setSlideIndex(slideIndex+1);
//         }
//       }
    
//       const handlePrevImg = () => {
//         if(slideIndex !== 0) {
//           setSlideIndex(slideIndex-1);
//         }
//       }

//     return (
//         <div className='home-container about-wrapper' id="aboutId">
//             <div className='aboutcard-wrapper'>
//                 <div className='aboutcard-container'>
//                 {cardData.map((data, index) => (
//                         <AboutCard key={index} data={data}/>
//                 ))}
//                 </div>
//             </div>
//             <div className='service-sub-container-mobile'>
//                 <div class="preview-button-left" onClick={handlePrevImg}>&#10095;</div>
//                 <div class="preview-button-right" onClick={handleNextImg}>&#10094;</div>
//                 <Slider setSlideIndex={setSlideIndex} slideIndex={slideIndex}>
//                     {cardData.map((item, index) => (
//                         <div className='aboutcard'>
//                             <div className='aboutcard-count'>{item.count}{item.title !== "Countries" && '+'} </div>
//                             <div className='aboutcard-title'>{item.title}</div>
//                         </div>
//                     ))}
//                 </Slider>
//                 </div>
//             <TextField data={data}/>
//         </div>
//     )
// }

// export default About;


import React, { useState, useEffect, useRef } from 'react';
import TextField from '../components/TextField';
import AboutCard from '../components/AboutCard';
import Slider from '../components/slider/Slider';

const About = () => {
    const [slideIndex, setSlideIndex] = useState(0);
    const [isInView, setIsInView] = useState(false); // Controls when the count should start
    const aboutRef = useRef(null); // Reference to the section
    
    // Initial card data with target counts
    const cardData = [
        { title: "Countries", count: 3 },
        { title: "Customers", count: 300 },
        { title: "Years of Experience", count: 8 },
        { title: "Completed Projects", count: 100 },
    ];

    // animated counts state, initializing all to zero
    const [animatedCounts, setAnimatedCounts] = useState(
        cardData.map(() => 0)
    );
    
    // // Set up the observer to start counting when in view
    // useEffect(() => {
    //     const observer = new IntersectionObserver((entries) => {
    //         if (entries[0].isIntersecting) {
    //             setIsInView(true);
    //         }
    //     }, { threshold: 0.5 });

    //     if (aboutRef.current) {
    //         observer.observe(aboutRef.current);
    //     }

    //     return () => {
    //         if (aboutRef.current) observer.unobserve(aboutRef.current);
    //     };
    // }, []);

    const startCounting = () => {
        cardData.forEach((card, index) => {
            let startCount = 0;
            const increment = card.count / 100;

            const interval = setInterval(() => {
                startCount += increment;
                setAnimatedCounts((prevCounts) => {
                    const updatedCounts = [...prevCounts];
                    updatedCounts[index] = Math.min(Math.ceil(startCount), card.count); //stops at the target count
                    return updatedCounts;
                });
                if (startCount >= card.count) clearInterval(interval);
            }, 10);

            return () => clearInterval(interval);
        });
    };
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            const [entry] = entries;
            if (entry.isIntersecting) {
                setIsInView(true);
            } else {
                setIsInView(false);
                setAnimatedCounts(cardData.map(() => 0)); // Reset counts when out of view
            }
        }, { threshold: 0.5 });

        if (aboutRef.current) observer.observe(aboutRef.current);

        return () => {
            if (aboutRef.current) observer.unobserve(aboutRef.current);
        };
    }, []);

    useEffect(() => {
        let interval;
        if (isInView) {
            startCounting();
            interval = setInterval(() => {
                startCounting();
            }, 10000); // Repeat every 10 seconds
        }
        return () => clearInterval(interval);
    }, [isInView]);

    return (
        <div className="home-container about-wrapper" id="aboutId" ref={aboutRef}>
            <div className="aboutcard-wrapper">
                <div className="aboutcard-container">
                    {cardData.map((data, index) => (
                        <AboutCard key={index} data={{ ...data, count: animatedCounts[index] }} />
                    ))}
                </div>
            </div>
            <div className="service-sub-container-mobile">
                <Slider setSlideIndex={setSlideIndex} slideIndex={slideIndex}>
                    {cardData.map((item, index) => (
                        <div className="aboutcard" key={index}>
                            <div className="aboutcard-count">
                                {animatedCounts[index]}
                                {item.title !== "Countries" && "+"}
                            </div>
                            <div className="aboutcard-title">{item.title}</div>
                        </div>
                    ))}
                </Slider>
            </div>
            <TextField data={{
                title: "About",
                titleY: "Us",
                paragraph: `VoxxSys operates with a straightforward vision: to create simple yet sophisticated digital solutions that represent your brand and enhance your business's efficiency. Leveraging state-of-the-art technologies, VoxxSys ensures high productivity and efficiency in all our digital products.
                We provide a wide range of digital solutions, from static websites to e-commerce platforms that expand your reach globally. VoxxSys also develops ERP, CRM, and HRM systems to support professional, sustainable, and efficient work environments.\n
                With a strong foundation since the early 2000s and an experienced board of directors, we are proud to be at the forefront, supported by a skilled team dedicated to delivering high-quality products. VoxxSys presents itself as a trustworthy global label our clients can rely on.
                Join us on a journey of growth, where your brand’s digital potential is not just realized but elevated.`,
                buttonTitle: "Read More"
            }}/>
        </div>
    );
};

export default About;
