import React from 'react';

const Connect = () => {
  return (
    <div className='main-container' id="contactId">
        <div className='title'>Connect With {" "}<span className='title-y'>Us </span></div>
        <div className='main-para'>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed</div>
        <div className='connect-sub-container'>
            <div className='col1'>
                <div className='input connect-input-div'>
                    <label className='input-label connect-label'>Name</label>
                    <input type="text" className='input-field'/>
                </div>
                <div className='input connect-input-div'>
                    <label className='input-label connect-label'>Contact No</label>
                    <input type="text" className='input-field'/>
                </div>
                <div className='input connect-input-div'>
                    <label className='input-label connect-label'>Email</label>
                    <input type="text" className='input-field'/>
                </div>
                <div className='input connect-input-div'>
                    <label className='input-label connect-label'>Subject</label>
                    <input type="text" className='input-field'/>
                </div>
                <div className='input connect-input-div'>
                    <label className='input-label connect-label'>Description</label>
                    <textarea type="textarea" className='input-field textarea' rows={5} cols={20}></textarea>
                </div>
                <div className='navbar-option'>
                    <div className='connect-button'>Send Message</div>
                </div>
            </div>
            <div className='col2'>
                <div className='title2'>Contact Us</div>
                <div className='connect-address-div'>
                    <div className='connect-address-sub-div'>
                        <div className='sub-text'>info.sl@voxxsys.com</div>
                        <div className='sub-text'>18/1, Koswatte Road,</div>
                        <div className='sub-text'>Nawala,</div>
                        <div className='sub-text'>Sri Lanka.</div>
                        <div className='title2'>+94 777 170 107</div>
                    </div>
                    <div className='connect-address-sub-div'>
                        <div className='sub-text'>info.nz@voxxsys.com</div>
                        <div className='sub-text'>8B Target Court, Wairau Valley,</div>
                        <div className='sub-text'>Auckland 0627,</div>
                        <div className='sub-text'>New Zealand</div>
                        <div className='title2'>+64 22 6949 885</div>
                    </div>
                    <div className='connect-address-sub-div'>
                        <div className='sub-text'>info.dubai@voxxsys.com</div>
                        <div className='sub-text'>Al Barsha 2, Al Barsha Second,</div>
                        <div className='sub-text'>United Arab Emirates</div>
                        <div className='title2'>+971 55 194 5877</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Connect;