import React from 'react';

const TextField = ({ data, head }) => {
  return (
    <div className={`textfield-container ${head === 'home' ? 'home-text-div' : ''}`}>
      <div className='textfield-title'>{data.title} {data.titleY && <span className='title-y'>{data.titleY}</span>}</div>
      {data.subTitle && <div className='textfield-sub-title'>{data.subTitle}</div>}
      <div className={`textfield-paragraph ${head === 'home' ? '' : 'about-para'}`}>
        {data.paragraph.split('\n').map((line, index) => (
          <span key={index}>
            {line}
            <br />
          </span>
        ))}
      </div>
      <div className='button1-div'>
        <div className='button-1'>{data.buttonTitle}</div>
      </div>
    </div>
  )
}

export default TextField;