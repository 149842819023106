// // Import necessary dependencies
// import React, { useEffect, useState } from 'react';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/css';
// import 'swiper/css/navigation';
// import lakrandi from '../assest/team/lakrandi.jpeg';
// import sinely from '../assest/team/sinely.jpeg';
// import dulmi from '../assest/team/dulmi.jpeg';
// import nawodh from '../assest/team/nawodh.jpeg';
// import shehan from '../assest/team/shehan.jpeg';
// import hirusha from '../assest/team/hirusha.jpeg';
// import pasindu from '../assest/team/pasindu.jpeg';

// const Team = () => {
//     const [images] = useState([
//         { img: shehan, name: "Charith Shehan" },
//         { img: sinely, name: "Sinely Dhyan" },
//         { img: pasindu, name: "Pasindu Gihan" },
//         { img: lakrandi, name: "Lakrandi Ayeshani" },
//         { img: hirusha, name: "Hirusha Senarathne" },
//         { img: dulmi, name: "Dulmi Saubhagya" },
//         { img: nawodh, name: "Nawodh Kaveendra" }
//     ]);

//     return (
//         <div className='main-container'>
//             <div className='title'>
//                 We’ve got an entire <span className='title-y'>team</span> dedicated to supporting you and your Business
//             </div>
//             <div className='main-para'>
//                 Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
//             </div>
            
//             <Swiper
//                 spaceBetween={30}
//                 slidesPerView={3}
//                 centeredSlides={true}
//                 loop={true}
//                 autoplay={{ delay: 5000 }}
//                 navigation
//                 pagination={{ clickable: true }}
//                 breakpoints={{
//                     640: { slidesPerView: 1 },
//                     800: { slidesPerView: 2 },
//                     1300: { slidesPerView: 3 }
//                 }}
//                 className='team-swiper'
//             >
//                 {images.map((imgSrc, index) => (
//                     <SwiperSlide key={index} className='team-img-card'>
//                         <img src={imgSrc.img} alt={imgSrc.name} className='team-img' />
//                         <div className='team-name'>{imgSrc.name}</div>
//                     </SwiperSlide>
//                 ))}
//             </Swiper>
//             <swiper-container
//                 slides-per-view="3"
//                 grid-rows="3"
//                 mousewheel-force-to-axis="true"
//                 >
//                {/* {images.map((imgSrc, index) => (
//                     <SwiperSlide key={index} className='team-img-card'>
//                         <img src={imgSrc.img} alt={imgSrc.name} className='team-img' />
//                         <div className='team-name'>{imgSrc.name}</div>
//                     </SwiperSlide>
//                 ))} */}
//                 <swiper-slide>Slide 2</swiper-slide>
//                 <swiper-slide>Slide 3</swiper-slide>
//                 <swiper-slide>Slide 2</swiper-slide>
//                 <swiper-slide>Slide 3</swiper-slide>
//                 <swiper-slide>Slide 2</swiper-slide>
//                 <swiper-slide>Slide 3</swiper-slide>
//                 <swiper-slide>Slide 2</swiper-slide>
//                 <swiper-slide>Slide 3</swiper-slide>
//             </swiper-container>

//         </div>
//     );
// };

// export default Team;







// import React, { useRef, useState } from 'react';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/css';
// import 'swiper/css/navigation';
// import { Autoplay } from 'swiper/modules';
// import lakrandi from '../assest/team/lakrandi.jpeg';
// import sinely from '../assest/team/sinely.jpeg';
// import dulmi from '../assest/team/dulmi.jpeg';
// import nawodh from '../assest/team/nawodh.jpeg';
// import shehan from '../assest/team/shehan.jpeg';
// import hirusha from '../assest/team/hirusha.jpeg';
// import pasindu from '../assest/team/pasindu.jpeg';

// const Team = () => {
//     const [images] = useState([
//         { img: shehan, name: "Charith Shehan" },
//         { img: sinely, name: "Sinely Dhyan" },
//         { img: pasindu, name: "Pasindu Gihan" },
//         { img: lakrandi, name: "Lakrandi Ayeshani" },
//         { img: hirusha, name: "Hirusha Senarathne" },
//         { img: dulmi, name: "Dulmi Saubhagya" },
//         { img: nawodh, name: "Nawodh Kaveendra" }
//     ]);

//     // Swiper references for custom navigation
//     const swiper3Ref = useRef(null);
//     const swiper2Ref = useRef(null);
//     const swiper1Ref = useRef(null);

//     const handlePrev = (swiperRef) => swiperRef.current?.slidePrev();
//     const handleNext = (swiperRef) => swiperRef.current?.slideNext();

//     return (
//         <div className='main-container'>
//             <div className='title'>We’ve got an entire <span className='title-y'>team</span> dedicated to supporting you and your Business</div>
//             <div className='main-para'>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed</div>

//             {/* Display 3 images at a time */}
//             {/* <div className='team-img-container images3'>
//                 <button className="custom-arrow left" onClick={() => handlePrev(swiper3Ref)}>&#10094;</button>
//                 <Swiper
//                     slidesPerView={3}
//                     spaceBetween={10}
//                     loop={true}
//                     autoplay={{ delay: 5000 }}
//                     onSwiper={(swiper) => (swiper3Ref.current = swiper)}
//                     modules={[Autoplay]}
//                 >
//                     {images.map((imgSrc, index) => (
//                         <SwiperSlide key={index} className="team-img-card">
//                             <img src={imgSrc.img} alt={imgSrc.name} className='team-img' />
//                             <div className='team-name'>{imgSrc.name}</div>
//                         </SwiperSlide>
//                     ))}
//                 </Swiper>
//                 <button className="custom-arrow right" onClick={() => handleNext(swiper3Ref)}>&#10095;</button>
//             </div> */}

//             {/* Display 2 images at a time */}
//             <div className='team-img-container images3'>
//                 <div className="img-preview-button-left" onClick={() => handleNext(swiper2Ref)}>&#10094;</div>           
//                 <Swiper
//                     slidesPerView={2}
//                     // spaceBetween={10}
//                     loop={true}
//                     marginLeft={0}
//                     marginRight={0}
//                     autoplay={{ delay: 5000 }}
//                     onSwiper={(swiper) => (swiper2Ref.current = swiper)}
//                     modules={[Autoplay]}
//                 >
//                     <div className='team-img-div'>
//                         {images.map((imgSrc, index) => (
//                             <SwiperSlide key={index} className='swiper'>
//                                 <div className='team-img-card'>
//                                     <img src={imgSrc.img} alt={imgSrc.name} className='team-img' />
//                                     <div className='team-name'>{imgSrc.name}</div>
//                                 </div>
//                             </SwiperSlide>
//                         ))}
//                     </div>
//                 </Swiper>
//                 <div className="img-preview-button-right" onClick={() => handlePrev(swiper2Ref)}>&#10095;</div>                
//             </div>

//             {/* Display 1 image at a time */}
//             {/* <div className='team-img-container images1'>
//                 <button className="custom-arrow left" onClick={() => handlePrev(swiper1Ref)}>&#10094;</button>
//                 <Swiper
//                     slidesPerView={1}
//                     spaceBetween={5}
//                     loop={true}
//                     autoplay={{ delay: 5000 }}
//                     onSwiper={(swiper) => (swiper1Ref.current = swiper)}
//                     modules={[Autoplay]}
//                 >
//                     {images.map((imgSrc, index) => (
//                         <SwiperSlide key={index} className="team-img-card">
//                             <img src={imgSrc.img} alt={imgSrc.name} className='team-img' />
//                             <div className='team-name'>{imgSrc.name}</div>
//                         </SwiperSlide>
//                     ))}
//                 </Swiper>
//                 <button className="custom-arrow right" onClick={() => handleNext(swiper1Ref)}>&#10095;</button>
//             </div> */}
//         </div>
//     );
// }

// export default Team;



import React, { useEffect, useState } from 'react';
import lakrandi  from '../assest/team/lakrandi.jpeg';
import sinely from '../assest/team/sinely.jpeg';
import dulmi from '../assest/team/dulmi.jpeg';
import nawodh from '../assest/team/nawodh.jpeg';
import shehan from '../assest/team/shehan.jpeg';
import hirusha from '../assest/team/hirusha.jpeg';
import pasindu from '../assest/team/pasindu.jpeg';

const Team = () => {
    const [startIndex3, setStartIndex3] = useState(0);
    const [startIndex2, setStartIndex2] = useState(0);
    const [startIndex1, setStartIndex1] = useState(0);

    const [images, setImages] = useState([
        {
            img:shehan,
            name: "Charith Shehan",
            title: "voxxsys_team_shehan"
        },
        {
            img: sinely,
            name: "Sinely Dhyan",
            title: "voxxsys_team_sinely"
        },
        {
            img: pasindu,
            name: "Pasindu Gihan",
            title: "voxxsys_team_pasindu"
        },
        {
            img: lakrandi,
            name: "Lakrandi Ayeshani",
            title: "voxxsys_team_lakrandi"
        },
        {
            img: hirusha,
            name: "Hirusha Senarathne",
            title: "voxxsys_team_hirusha"
        },
        {
            img: dulmi,
            name: "Dulmi Saubhagya",
            title: "voxxsys_team_dulmi"
        },
        {
            img: nawodh,
            name: "Nawodh Kaveendra",
            title: "voxxsys_team_nawodh"
        }
     ]);

    const displayImages3 = images.slice(startIndex3, startIndex3 + 3);
    const displayImages2 = images.slice(startIndex2, startIndex2 + 2);
    const displayImages1 = images.slice(startIndex1, startIndex1 + 1);

    // const handleNextImg3Set = () => {
    //     if (startIndex3 + 3 < images.length) {
    //         setStartIndex3(startIndex3 + 3);
    //     } else {
    //         setStartIndex3(0);
    //     }
    // };

    // const handlePrevImg3Set = () => {
    //     if (startIndex3 > 0) {
    //         setStartIndex3(startIndex3 - 3);
    //     } else {
    //         setStartIndex3(Math.max(images.length - 3, 0));
    //     }
    // };

    // const handleNextImg2Set = () => {
    //     if (startIndex2 + 2 < images.length) {
    //         setStartIndex2(startIndex2 + 2);
    //     } else {
    //         setStartIndex2(0);
    //     }
    // };

    // const handlePrevImg2Set = () => {
    //     if (startIndex2 > 0) {
    //         setStartIndex2(startIndex2 - 2);
    //     } else {
    //         setStartIndex2(Math.max(images.length - 2, 0));
    //     }
    // };

    // const handleNextImg1Set = () => {
    //     if (startIndex1 + 1 < images.length) {
    //         setStartIndex1(startIndex1 + 1);
    //     } else {
    //         setStartIndex1(0);
    //     }
    // };

    // const handlePrevImg1Set = () => {
    //     if (startIndex1 > 0) {
    //         setStartIndex1(startIndex1 - 1);
    //     } else {
    //         setStartIndex1(images.length - 1);
    //     }
    // };
    const handleNextImg3Set = () => {
        if (startIndex3 + 3 < images.length) {
            setStartIndex3(startIndex3 + 1);
        } else {
            setStartIndex3(0);
        }
    };

    const handlePrevImg3Set = () => {
        if (startIndex3 > 0) {
            setStartIndex3(startIndex3 - 1);
        } else {
            setStartIndex3(Math.max(images.length - 3, 0));
        }
    };

    const handleNextImg2Set = () => {
        if (startIndex2 + 2 < images.length) {
            setStartIndex2(startIndex2 + 1);
        } else {
            setStartIndex2(0);
        }
    };

    const handlePrevImg2Set = () => {
        if (startIndex2 > 0) {
            setStartIndex2(startIndex2 - 1);
        } else {
            setStartIndex2(Math.max(images.length - 2, 0));
        }
    };

    const handleNextImg1Set = () => {
        if (startIndex1 + 1 < images.length) {
            setStartIndex1(startIndex1 + 1);
        } else {
            setStartIndex1(0);
        }
    };

    const handlePrevImg1Set = () => {
        if (startIndex1 > 0) {
            setStartIndex1(startIndex1 - 1);
        } else {
            setStartIndex1(images.length - 1);
        }
    };

    useEffect(() => {
        const interval3 = setInterval(() => {
            handleNextImg3Set();
        }, 5000);
        return () => clearInterval(interval3);
    }, [startIndex3, images.length]);

    useEffect(() => {
        const interval2 = setInterval(() => {
            handleNextImg2Set();
        }, 5000);
        return () => clearInterval(interval2);
    }, [startIndex2, images.length]);

    useEffect(() => {
        const interval1 = setInterval(() => {
            handleNextImg1Set();
        }, 5000);
        return () => clearInterval(interval1);
    }, [startIndex1, images.length]);

    useEffect(() => {
        setStartIndex3(0);
        setStartIndex2(0);
        setStartIndex1(0);
    }, [images.length]);

    return (
        <div className='main-container'>
            <div className='title'>We’ve got an entire <span className='title-y'>team</span> dedicated to supporting you and your Business</div>
            <div className='main-para'>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed</div>
            
            <div className='team-img-container images3'>
                <div className="img-preview-button-left" onClick={handlePrevImg3Set}>&#10094;</div>
                <div className="img-preview-button-right" onClick={handleNextImg3Set}>&#10095;</div>
                <div className='images3'>
                    {displayImages3.map((imgSrc, index) => (
                        <div className='team-img-card' key={index}>
                            <img src={imgSrc.img} alt={imgSrc.title} className='team-img' />
                            <div className='team-name'>{imgSrc.name}</div>
                        </div>
                    ))}
                </div>
            </div>

            <div className='team-img-container images2'>
                <div className="img-preview-button-left" onClick={handlePrevImg2Set}>&#10094;</div>
                <div className="img-preview-button-right" onClick={handleNextImg2Set}>&#10095;</div>
                <div className='images2'>
                    {displayImages2.map((imgSrc, index) => (
                        <div className='team-img-card' key={index}>
                            <img src={imgSrc.img} alt={imgSrc.name} className='team-img' />
                            <div className='team-name'>{imgSrc.name}</div>
                        </div>
                    ))}
                </div>
            </div>

            <div className='team-img-container images1'>
                <div className="img-preview-button-left" onClick={handlePrevImg1Set}>&#10094;</div>
                <div className="img-preview-button-right" onClick={handleNextImg1Set}>&#10095;</div>
                <div className='images1'>
                    {displayImages1.map((imgSrc, index) => (
                        <div className='team-img-card' key={index}>
                            <img src={imgSrc.img} alt={imgSrc.name} className='team-img' />
                            <div className='team-name'>{imgSrc.name}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Team;
