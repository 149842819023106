import React from 'react';
import logo from '../assest/1.png';
import { GoSquareFill } from "react-icons/go";

const Footer = () => {
  return (
    <div className='footer-container'>
        <div className='footer-wrapper'>
        <div className='footer-container-col'>
            <div className='footer1'>
                <div className='logo footer-img-div'>
                    <img src={logo} alt="logo" className='logo-image' />
                </div>
                <div className='footer-text'>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam</div>
            </div>
            <div className='footer2'>
                <div className='footer-title'>Site Map</div>
                <div className='footer-sub-topic'><GoSquareFill size={12} className='square-icon'/>About Us</div>
                <div className='footer-sub-topic'><GoSquareFill size={12} className='square-icon'/>Products / Services</div>
                <div className='footer-sub-topic'><GoSquareFill size={12} className='square-icon'/>Testimonials</div>
                <div className='footer-sub-topic'><GoSquareFill size={12} className='square-icon'/>Careers</div>
                <div className='footer-sub-topic'><GoSquareFill size={12} className='square-icon'/>News / Events</div>            
            </div>
            <div className='footer3'>
                <div className='footer-title'><span className='title-y'>Our {" "}</span>Service</div>
                <div className='footer-sub-topic'>About Us</div>
                <div className='footer-sub-topic'>Products / Services</div>
                <div className='footer-sub-topic'>Testimonials</div>
                <div className='footer-sub-topic'>Careers</div>
                <div className='footer-sub-topic'>News / Events</div>
            </div>
            <div className='footer4'>
                <div className='footer-title'>Get Updates</div>
                <div className='footer-text'>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed</div>
                <div className='input footer-input'>
                    <label className='input-label'>Email</label>
                    <input type="text" className='input-field'/>
                </div>
                <div className='button-1 footer-button'>Subscribe</div>
            </div>
        </div>
        <div className='footer-text copyright'>Copyright © 2024 VoxxSys International®. All rights reserved.</div>
        </div>
    </div>
  )
}

export default Footer